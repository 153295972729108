import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import SEO from '../../components/seo';
import SidePhoto from '../../components/SidePhoto';
import TrailTitle from '../../components/TrailTitle';
import { graphql } from 'gatsby';

const InpatientPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  return (
    <>
      <SEO title='Layanan Rawat Inap' />
      <Img fluid={data.inpatient.cover.value.childImageSharp.fluid} />
      <Row
        css={{
          minHeight         : '75vh',
          backgroundImage   : `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat  : 'no-repeat',
          backgroundPosition: 'top right',
        }}
        className='pt-5'>
        <Col
          sm        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'
          css       = {{ justifyContent: 'space-between' }}>
          <TrailTitle open={open} className='pb-2 mb-5'>
            <div>Layanan</div>
            <div>Rawat Inap</div>
          </TrailTitle>
          <SidePhoto>
            {data.inpatient.side.value.map((img, idx) => (
              <Img key={idx} fluid={img.childImageSharp.fluid} />
            ))}
          </SidePhoto>
        </Col>
        <Col className='d-flex flex-column align-items-center align-content-center pl-3 pr-3 pt-1'>
          <ReactMarkdown
            source     = {data.inpatient.description.value}
            escapeHtml = {false}
          />
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    inpatient: cockpitServices(cockpitId: { eq: "5fe05e723138660cda0001bf" }) {
      description {
        type
        value
      }
      cover: cover_photo {
        type
        value {
          childImageSharp {
            fluid(maxWidth: 1920, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      side: other_photos {
        type
        value {
          childImageSharp {
            fluid(toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default InpatientPage;
